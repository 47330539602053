
.imageIcon{
  margin:0;
  margin-inline: auto;
  font-size: 150px !important;
  color: rgb(167, 167, 167);
  transition: .2s ease;
  padding: 0;
  line-height: 0;
}
.imageIcon:hover{
  color: rgb(65, 65, 65);
  transform: scale(1.1);
  cursor: pointer;
}

input.inputFile{
  display: none;
}


.displayedImage{
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}


.displayedImage .image_container{
  text-align: center;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  
}
.displayedImage .image_container .closeButton{
  position: absolute;
  padding: 4px;
  background-color: rgba(193, 7, 7, 0.71);
  border-radius: 50%;
  top: 10px;
  right: 10px;
  color: #ffd1d1;
  cursor: pointer;
}

.displayedImage .image_container img{
  /* width: 50%; */
  width: 100%;
  
}