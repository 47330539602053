.custom_button{
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center; 
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.custom_button .custom_icon{
  background: rgba(0, 0, 0, 0.15);
    display: inline-block;
    padding: 0.375rem 0.75rem;
}
.custom_button .text{
  display: inline-block;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
}