


.ellisips_text{
  -webkit-box-orient: vertical; /* Sets the box orientation to vertical */
  -webkit-line-clamp: 1; /* Limits the text to 2 lines */
  text-overflow: ellipsis;
  overflow: hidden; /* Hides any overflowed text */
  width: 100%; /* Adjust as needed */
  color: #4f4f4f; /* Your desired text color */

}