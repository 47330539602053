
.drawer {
  z-index: 9999;
  width: 230px;
  position: fixed;
  top: 0;
  bottom: 0;
  /* right: 0; */
  background-color: #ffffff;
  padding: 10px;
  /* transform: translateX(calc(-100% - 20px)); */
  margin: 20px;
  border-radius:20px ;
  /* direction: rtl; */
  left: 0;
  transition: transform 0.2s;
  /* overflow-y: auto; */
  box-shadow: 0px 0px 10px  #c4c4c4; 
  transition: .3s ease;

  
}

.drawer.left {
  transform: translateX(-100%);
  direction: ltr;
  left: 0;
}

.drawer_overlay {
  background-color: transparent;
  transition: all 0.3s ease;
  display: none;
}

.drawer_title {
  direction: ltr;
  margin: auto ;
  text-align: center;
  border-bottom: 1px solid #aaa;
  padding-bottom: 10px;
}

.drawer.open .drawer_title {
  margin: auto;
}

.drawer_overlay.open {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 28%, 0.635);
  transition: all 0.3s ease;
}

.drawer.open {
  transform: translateX(0);
}

.drawer_colse_button {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    font-size: 20px;
    color: #484848;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    line-height: 0;
    height: 30px;
    /* border: 1px solid; */
    border-radius: 50%;
    box-shadow: 0 0 10px #bebebe;
    transition: .2s ease;

}

.drawer.open .drawer_colse_button{
  transform: translateX(50%) rotate(180deg);
  transform-origin: center;

}


.drawer.open .drawer_colse_button{
  
}

.drawer_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
}
.drawer_content.withTitle {
  margin-top: 10px;
}

.drawer_button {
  display: flex;
  align-items: center;
  background-color: #e8e4e4;
  padding: 6px 8px;
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}

.drawer_button.active {
  background-color: hsl(210, 61%, 38%);
}
.drawer_button.active .btn_icon {
  color: hsl(0, 0%, 94%);
}
.drawer_button.active:hover {
  background-color: hsl(210, 61%, 38%);
}

.drawer_button.active .btn-text {
  color: #fff;
}

.drawer_button:hover {
  background-color: #d1cfcf;
}

.drawer_button .btn-text {
  font-size: 12px;
}


.drawer_button.close .btn_icon{
  margin-inline: auto;
}
