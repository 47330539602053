/* Add any other global styles you have */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  z-index: 1000000000000;
  /* Ensure the overlay is above other content */
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: min(680px, 96%);
  max-height: 90vh;
  z-index: 1000000000000000;
  overflow: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary, #009418);

  /* web-h1 */
  font-family: Tajawal;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.exitModal {
  cursor: pointer;
}

.modalChildren {
  /* Add any styles you want for the modal content */
}

@media (max-width: 565px) {
  .modalChildren .container {
    flex-wrap: wrap;
  }

  .modalChildren .container .product-image {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
}

.modalOverlay.show {
  animation: fadeIn 0.5s ease forwards;
}

.modalOverlay.hide {
  animation: fadeOut 0.5s ease forwards;
}

.modalContainer.show {
  animation: scaleIn 0.5s ease forwards;
}

.modalContainer.hide {
  animation: scaleOut 0.5s ease forwards;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: min(680px, 96%);
  max-height: 90vh;
  z-index: 10019999999;
  overflow: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary, #009418);
  font-family: Tajawal;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.exitModal {
  cursor: pointer;
}

.modalChildren {
}

@media (max-width: 565px) {
  .modalChildren .container {
    flex-wrap: wrap;
  }

  .modalChildren .container .product-image {
    width: 100%;
  }
}

.modalOverlay.show {
  animation: fadeIn 0.5s ease forwards;
}

.modalOverlay.hide {
  animation: fadeOut 0.5s ease forwards;
}

.modalContainer.show {
  animation: scaleIn 0.5s ease forwards;
}

.modalContainer.hide {
  animation: scaleOut 0.5s ease forwards;
}

@keyframes slideIn {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
}

.modalOverlay.show {
  animation: fadeIn 0.5s ease forwards;
}

.modalOverlay.hide {
  animation: fadeOut 0.5s ease forwards;
}

.modalContainer.show {
  animation: slideIn 0.5s ease forwards;
}

.modalContainer.hide {
  animation: slideOut 0.5s ease forwards;
} */
