

.header_container{
  width:  100%;
  background-color: #FFF;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_input_icon{
  display: flex;
  align-items: center;
  background-color: #ececec ;
  gap: 10px;
  width: fit-content;
  overflow: hidden;
  border-radius: 20px;
  padding-inline: 20px ;
  
}

.search_input_icon input{
  padding:  10px;
  background-color: #ececec ;
  border: none;
  outline: none;
}

.searchIcon{
 line-height: 0;
 padding: 0;
 margin: 0;
 font-size: 20px;
}

.header_user_image{
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgb(168, 168, 168) ;
  overflow: hidden;

}
.header_user_image img{
  width: 100%;
}